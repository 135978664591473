/* quill.css */

/* Base styling for the Quill content area */
.quill-content {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  max-width: 100%;
  color: #333;
  margin: 0 auto;
  padding: 16px;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

/* Paragraph styling */
.quill-content p {
  margin: 1em 0;
}

/* Heading styles */
.quill-content h1 {
  font-size: 2.25em;
  font-weight: 600;
  margin: 1em 0;
}

.quill-content h2 {
  font-size: 1.875em;
  font-weight: 600;
  margin: 1em 0;
}

.quill-content h3 {
  font-size: 1.5em;
  font-weight: 600;
  margin: 1em 0;
}

.quill-content h4 {
  font-size: 1.25em;
  font-weight: 600;
  margin: 1em 0;
}

/* Bold and italic text */
.quill-content strong {
  font-weight: bold;
}

.quill-content em {
  font-style: italic;
}

/* Underlined and strikethrough text */
.quill-content u {
  text-decoration: underline;
}

.quill-content s {
  text-decoration: line-through;
}

/* Blockquote styling */
.quill-content blockquote {
  border-left: 4px solid #ddd;
  margin: 1em 0;
  padding-left: 16px;
  font-style: italic;
  color: #555;
}

/* List styling */
.quill-content ul {
  list-style-type: disc;
  padding-left: 40px;
  margin: 1em 0;
}

.quill-content ol {
  list-style-type: decimal;
  padding-left: 40px;
  margin: 1em 0;
}

.quill-content li {
  margin-bottom: 0.5em;
}

/* Code block styling */
.quill-content pre {
  background-color: #f5f5f5;
  padding: 16px;
  border-radius: 4px;
  margin: 1em 0;
  overflow-x: auto;
}

.quill-content code {
  background-color: #f5f5f5;
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 0.95em;
  font-family: monospace;
}

/* Link styling */
.quill-content a {
  color: #1e90ff;
  text-decoration: underline;
}

/* Image styling */
.quill-content img {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  margin: 1em 0;
}

/* Alignments */
.quill-content .ql-align-center {
  text-align: center;
}

.quill-content .ql-align-right {
  text-align: right;
}

.quill-content .ql-align-justify {
  text-align: justify;
}

/* Custom size styling */
.quill-content .ql-size-small {
  font-size: 0.75em;
}

.quill-content .ql-size-large {
  font-size: 1.5em;
}

.quill-content .ql-size-huge {
  font-size: 2.5em;
}

/* Custom text color */
.quill-content .ql-color-red {
  color: red;
}

.quill-content .ql-color-green {
  color: green;
}

.quill-content .ql-color-blue {
  color: blue;
}

/* Custom background color */
.quill-content .ql-bg-yellow {
  background-color: yellow;
}

.quill-content .ql-bg-gray {
  background-color: #f0f0f0;
}

/* Indentation for lists */
.quill-content .ql-indent-1 {
  margin-left: 20px;
}

.quill-content .ql-indent-2 {
  margin-left: 40px;
}

/* Direction (LTR and RTL) */
.quill-content .ql-direction-rtl {
  direction: rtl;
  text-align: right;
}

/* Video styling */
.quill-content iframe {
  max-width: 100%;
  border-radius: 4px;
  margin: 1em 0;
}
